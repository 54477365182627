import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import useSignIn from "react-auth-kit/hooks/useSignIn";
import * as yup from "yup";

import { useAuthLogin } from "../../lib/Auth/auth-login";
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import { useNavigate } from "react-router-dom";

import FormMessage from "../common/FormMessage";
import { SmallLoading } from "../common/Loader/SmallLoading";
import { ToastContainer, toast } from "react-toastify";
import convertToJWT from "../../lib/Auth/convertToJWT";
import { useEffect } from "react";

interface LoginFormInputs {
  phone_number: string;
  password: string;
}

const LoginForm = () => {
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(schema),
  });

  let { mutate: login, isLoading: isSigningIn } = useAuthLogin({
    async onSuccess(data: any) {
      // FIXME: Temporary configuration: Convert the received token to JWT format until the backend sends JWT tokens directly.
      const jwtToken = await convertToJWT(data.auth_token);

      if (signIn({
        auth: {
          token: jwtToken
        },
        userState: {
          name: data.name,
          email: data.email,
          id: data.id,
          phone: data.phone_number,
          role: data.role,
          phone_number: data.phone_number,
          image: data.image,
          auth_token: data.auth_token
        }
      })) {
        navigate('/governorates')
      } else {
        toast.error("حدث خطأ أثناء تسجيل الدخول");
      }
    },
    onError(err: any) {
      if (err.response && err.response.status === 401) {
        toast.error("لم يتم العثور على المستخدم");
      } else {
        toast.error("حدث خطأ أثناء تسجيل الدخول");
      }
    },
  });

  const onSubmit = (values: LoginFormInputs) => {
    if (!isSigningIn) {
      login(values);
    }
  };

  useEffect(() => {

    if (isAuthenticated) {
      navigate("/governorates")
    }
  }, [])


  return (
    <>
      <ToastContainer position="top-right" rtl={true} />
      <div className="md:max-w-[50%] mx-auto flex   flex-col gap-9">
        <div className="rounded-sm border border-stroke  bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
            <h3 className="font-semibold text-xl text-center text-black dark:text-white">
              تسجل الدخول
            </h3>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className=" space-y-5.5  p-6.5"
          >
            <input
              placeholder="رقم الهاتف"
              type="tel"
              className="w-full rounded border-[1.5px] placeholder:text-right border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              {...register("phone_number")}
            />
            {errors.phone_number && (
              <FormMessage message={errors.phone_number.message} />
            )}

            <input
              className="w-full rounded border-[1.5px] placeholder:text-right border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              type="password"
              placeholder="كلمة المرور"
              {...register("password")}
            />
            {errors.password && (
              <FormMessage message={errors.password.message} />
            )}

            {/* <div className="mt-5 mb-5.5 rtl flex items-center justify-between">
             <Link href="#" className="text-sm text-primary">
                نسيت كلمة المرور؟
              </Link>  
            </div> */}
            <button
              disabled={isSigningIn}
              className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray-1"
              type="submit"
            >
              {isSigningIn ? <SmallLoading /> : "تسجيل الدخول"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
const schema = yup.object().shape({
  phone_number: yup
    .string()
    .matches(/^[0-9]+$/, "يجب أن يحتوي رقم الهاتف على أرقام فقط")
    .min(10, "يجب أن يتكون رقم الهاتف من 10 أرقام على الأقل")
    .max(15, "يجب أن يتكون رقم الهاتف من 15 رقمًا كحد أقصى")
    .required("الرجاء إدخال رقم هاتف صالح"),
  password: yup.string().required("كلمة المرور مطلوبة"),
});
