import "./index.css";
import { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import routes from "./routes";
import Loader from "./components/common/Loader";
 
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthProvider from "react-auth-kit";
import createStore from "react-auth-kit/createStore";
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import Login from "./pages/Login"; 
const DefaultLayout = lazy(() => import("./layout/DefaultLayout"));

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [queryClient] = useState(() => new QueryClient());

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const store = createStore({
    authName: "_wahaj_auth",
    authType: "cookie",
    cookieDomain: window.location.hostname,
    cookieSecure: false,
  });

  return loading ? (
    <Loader />
  ) : (
    <>
      <AuthProvider store={store}>
        <QueryClientProvider client={queryClient}>
       
          <Routes>
            <Route element={<DefaultLayout />}>
              {routes?.map((routes, index) => {
                const { path, component: Component } = routes;
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <Suspense fallback={<Loader />}>
                        <RequireAuth fallbackPath={"/login"}>
                        <Component />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                );
              })}
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
        </QueryClientProvider>
      </AuthProvider>
    </>
  );
}

export default App;
